import React, { useState, useEffect } from 'react';
import BrandLogo from '../../images/logo-with-name.svg';
import TransitionLink from 'gatsby-plugin-transition-link';
import Link from 'gatsby-plugin-transition-link';

// Animated burger button and stylesheet
import Burger from '@animated-burgers/burger-slip';
import '@animated-burgers/burger-slip/dist/styles.css';

const Logo = () => (
  <Link to="/">
    <img src={BrandLogo} alt="credup logo" />
  </Link>
);

const MobileMenu = ({ isOpen, setIsOpen }) => {
  return (
    <>
      <nav className="mobile-menu">
        <ul className="menu">
          <li>
            <Link to="/about">the company</Link>
          </li>
          <li>
            <Link to="/#get-in-touch">get in touch</Link>
          </li>
          <li>
            <Link to="/borrow-now">borrow now</Link>
          </li>
        </ul>
      </nav>
    </>
  );
};

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [bodyMounted, setBodyMounted] = useState(false);

  // notify that body has mounted
  useEffect(() => {
    setBodyMounted(true);
  }, []);

  useEffect(() => {
    if (bodyMounted) {
      document.querySelector('body').classList.toggle('open');
    }
  }, [isOpen]);

  const handleOpen = () => {
    setIsOpen(!isOpen);
  };
  return (
    <>
      <header className="header">
        <div className="header-container">
          <Logo />
        </div>
        <nav className="header-nav">
          <ul>
            <li className="header-nav-element">
              <TransitionLink to="/about">the company</TransitionLink>
            </li>
            <li className="header-nav-element">
              <a href="/#get-in-touch">get in touch</a>
            </li>
            <li className="header-nav-element header-nav-element--cta">
              <Link to="/borrow-now">borrow now</Link>
            </li>
          </ul>
        </nav>
      </header>
      <div className="hamburger-btn-container" onClick={handleOpen}>
        <Burger isOpen={isOpen} />
        <MobileMenu />
      </div>
    </>
  );
};

export default Header;
