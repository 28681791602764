import React from 'react';
import { Tween, Controls, Reveal, Timeline } from 'react-gsap';

const animConfig = {
  ease: 'power1.inOut',
};

const FadeIn = ({ children, ...rest }) => (
  <Tween
    from={rest.from ?? { opacity: 0 }}
    duration={1}
    ease={rest.ease ?? animConfig.ease}
    {...rest}
  >
    {children}
  </Tween>
);

const FadeInTop = ({ children, ...rest }) => (
  <Tween
    from={rest.from ?? { y: '50px', opacity: 0 }}
    duration={1}
    ease={rest.ease ?? animConfig.ease}
    {...rest}
  >
    {children}
  </Tween>
);

const FadeInRight = ({ children, ...rest }) => (
  <Tween
    from={rest.from ?? { x: '-50px', opacity: 0 }}
    duration={1}
    ease={rest.ease ?? animConfig.ease}
    {...rest}
  >
    {children}
  </Tween>
);

const FadeInLeft = ({ children, ...rest }) => (
  <Tween
    from={rest.from ?? { x: '50px', opacity: 0 }}
    duration={1}
    ease={rest.ease ?? animConfig.ease}
    {...rest}
  >
    {children}
  </Tween>
);

// const Reveal = ({ children, ...rest }) => (
//   <Reveal {...rest} repeat={animConfig.repeat}>
//     {children}
//   </Reveal>
// );

export { FadeInTop, FadeInLeft, FadeInRight, Reveal, FadeIn, Tween, Timeline };
