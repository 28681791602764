import React from 'react';

import Logo from '../../images/logo.svg';

import TransitionLink from 'gatsby-plugin-transition-link';
import Link from 'gatsby-plugin-transition-link';

import { Reveal, FadeInTop } from '../animation';
import ReactTooltip from 'react-tooltip';

const Footer = () => (
  <div className="footer">
    <div className="container">
      <Reveal>
        <div className="footer-links">
          <FadeInTop stagger={0.3}>
            <Link to="/" className="footer-logo">
              <img alt="credup logo" src={Logo} />
            </Link>
            {/* TODO: link to FAQ and Types of Loans sections on about page */}
            <Link to="/about#faq-loans">FAQs.</Link>
            <Link to="/about#faq-loans">Types of loans.</Link>
          </FadeInTop>
        </div>
      </Reveal>
      <Reveal>
        <FadeInTop stagger={0.4}>
          <p className="footer-address">
            37 Razaq Balogun Street,
            <br />
            off Adeniran Ogunsanya,
            <br />
            Surulere, Lagos.
          </p>
        </FadeInTop>
      </Reveal>
      <Reveal>
        <div className="footer-bottom">
          <FadeInTop stagger={0.4}>
            <p className="footer-credit">&copy; 2021 Credup Limited</p>
            <p className="footer-solecompany">
              Developed by{' '}
              <a
                href="https://thesolecompany.com"
                target="_blank"
                data-tip
                data-for="solecompany"
              >
                {' '}
                Sole Company
              </a>
              <ReactTooltip id="solecompany" type="dark" effect="solid">
                <p style={{ textAlign: 'center' }}>👋 Hello...</p>
              </ReactTooltip>
            </p>
          </FadeInTop>
        </div>
      </Reveal>
    </div>
  </div>
);

export default Footer;
