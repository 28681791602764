import React from 'react';
import Header from '../header';
import Footer from '../footer';

import SEO from '../seo';

const Layout = ({ pageTitle, children, noFooter }) => {
  return (
    <>
      <SEO title={pageTitle} />
      <Header />
      <main>{children}</main>
      {noFooter ? '' : <Footer />}
    </>
  );
};

export default Layout;
